import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  imports: [CommonModule, MatIconModule],
  selector: 'app-tag',
  template: `
    <span class="tag" [ngClass]="'tag--' + innerClass() + ' ' + customClasses()">
      @if (!!label()) {
        {{ label() }}
      }

      <ng-content></ng-content>

      @if (removable()) {
        <button class="tag__btn" (click)="removeTag.emit()" type="button">
          <mat-icon class="tag__btn__icon" svgIcon="close"></mat-icon>
        </button>
      }
    </span>
  `,
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  customClasses = input<string>('');
  innerClass = input<string>('');
  label = input<string>();
  removable = input<boolean>(false);

  removeTag = output<void>();
}
